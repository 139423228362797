import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet";

export default function LocalBusiness() {

    const data = useStaticQuery(graphql`
    query LocalBusinessQuery {
      site {
        siteMetadata {
          siteUrl
          title
          phoneNumber
          faxNumber
          email
          addressRegion
          postalCode
          addressCountry
          streetAddress
          latitude
          longitude
          imageUrl
          openingHours
          businessTypes
        }
      }
    }
  `)

    const schema = {
        "@context": "https://schema.org",
        "@type": data.site.siteMetadata.businessTypes,
        "name": data.site.siteMetadata.title,
        "image": data.site.siteMetadata.imageUrl,
        "url": data.site.siteMetadata.siteUrl,
        "telephone": data.site.siteMetadata.phoneNumber,
        "currenciesAccepted": "USD",
        "paymentAccepted": "Cash, Check, Credit Card",
        "priceRange": "varies",
        "address": {
            "@type": "PostalAddress",
            "addressLocality": data.site.siteMetadata.addressLocality,
            "addressRegion": data.site.siteMetadata.addressRegion,
            "postalCode": data.site.siteMetadata.postalCode,
            "addressCountry": data.site.siteMetadata.addressCountry,
            "streetAddress": data.site.siteMetadata.streetAddress
        },
        "geo": {
            "@type": "GeoCoordinates",
            "latitude": data.site.siteMetadata.latitude,
            "longitude": data.site.siteMetadata.longitude
        },
        "openingHoursSpecification": {
            "@type": "OpeningHoursSpecification",
            "dayOfWeek": [
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday",
                "Sunday"
            ],
            "opens": "00:00",
            "closes": "23:59"
        },
    };

    return (
        <Helmet>
            <script type="application/ld+json">{JSON.stringify(schema)}</script>
        </Helmet>
    )
}