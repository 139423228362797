import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Helmet } from "react-helmet";

export default function Organization() {

  const data = useStaticQuery(graphql`
    query OrganizationQuery {
      site {
        siteMetadata {
          siteUrl
          title
          phoneNumber
          faxNumber
          email
          addressRegion
          postalCode
          addressCountry
          streetAddress
          sameAs
          imageUrl
          foundingDate
          legalName
        }
      }
    }
  `)

  const schema = {
    "@context": "https://schema.org",
    "@type": "Organization",
    "url": data.site.siteMetadata.siteUrl,
    "name": data.site.siteMetadata.title,
    "foundingDate": data.site.siteMetadata.foundingDate,
    "legalName" : data.site.siteMetadata.legalName,
    "logo": { 
      "@type": "ImageObject", 
      "inLanguage": "en-US", 
      "url": data.site.siteMetadata.imageUrl, 
      "width": 512,
      "height": 512,
      "caption": data.site.siteMetadata.title,
    },
    "sameAs": data.site.siteMetadata.sameAs,
    "address": {
      "@type": "PostalAddress",
      "addressLocality": data.site.siteMetadata.addressLocality,
      "addressRegion": data.site.siteMetadata.addressRegion,
      "postalCode": data.site.siteMetadata.postalCode,
      "addressCountry": data.site.siteMetadata.addressCountry,
      "streetAddress": data.site.siteMetadata.streetAddress
    },
    "email": data.site.siteMetadata.email,
    "faxNumber": data.site.siteMetadata.faxNumber,
    "telephone": data.site.siteMetadata.phoneNumber
  };

  return (
    <Helmet>
      <script type="application/ld+json">{JSON.stringify(schema)}</script>
    </Helmet>
  )
}