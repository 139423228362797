/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./header"
import SeoOrg from './SEO/organization'
import SeoLocBus from './SEO/localBusiness'
import SeoWebsite from './SEO/website'
import Helmet from 'react-helmet'
import './style.scss';


const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      <Helmet htmlAttributes={{ lang: `en`, class: `has-navbar-fixed-top` }}>
        <title>Arcane Wraps | Vinyl Wraps Cincinnati</title>
        <meta charset="UTF-8" />
        <meta name="robots" content="INDEX,FOLLOW" />
        <meta name="description" content="Arcane Wraps, based in Cincinnati, Ohio, is a mobile contract company providing vinyl wraps and graphic installations for commercial and consumer customization." />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="msapplication-TileColor" content="#5b2481" />
        <meta name="theme-color" content="#5b2481" />
        <link rel="apple-touch-icon" href="/icons/apple-touch-icon.png" sizes="180x180" />
        <link rel="icon" href="/favicon-32x32.png" type="image/png" sizes="32x32" />
        <link rel="icon" href="/favicon-16x16.png" type="image/png" sizes="16x16" />
        <link rel="mask-icon" href="/icons/safari-pinned-tab.svg" color="#5b2481" />
      </Helmet>
      <SeoOrg />
      <SeoLocBus />
      <SeoWebsite url="https://www.arcanewraps.com/" title="Arcane Wraps | Vinyl Wraps Cincinnati" />
      <div>
        <main>{children}</main>
        <footer> © Arcane Wraps {new Date().getFullYear()}</footer>
      </div>
    </>
  )
}

export default Layout
