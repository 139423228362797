import React from "react"
import { Helmet } from "react-helmet";

export default function WebSite(props) {
    const { title, url } = props

    const schema = {
        "@context": "http://schema.org",
        "@type": "WebSite",
        "url": url,
        "name": title,
        "inLanguage": "en-US",
    }

    return (
        <Helmet>
            <script type="application/ld+json">{JSON.stringify(schema)}</script>
        </Helmet>
    )
}
